import { useEffect } from 'react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { PrivacyPageQuery } from '../../generated/types';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { HomepageTemplate } from '../components/templates';
import { PrivacyPolicyContent } from '../modules/legal/PrivacyPolicyContent';

type PpPrivacyPage = PageProps<PrivacyPageQuery>;

function PrivacyPage({ data: { content } }: PpPrivacyPage): React.ReactElement {
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  return (
    <HomepageTemplate seo={content?.frontmatter?.seo}>
      <PrivacyPolicyContent content={content} sx={{ paddingBottom: 4, paddingTop: 10 }} />
    </HomepageTemplate>
  );
}

export const query = graphql`
  query PrivacyPage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "privacy" }, locale: { eq: $locale } }) {
      rawMarkdownBody
      frontmatter {
        locale
        title
        privacyDescription
        date(formatString: "DD MM YYYY")
        seo {
          title
          description
          image
        }
      }
    }
  }
`;

export default PrivacyPage;
